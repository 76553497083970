import dynamic from "next/dynamic";
import { PromotionBanner as PromotionBannerUI } from "@business-finland/wif-ui-lib/dist/PromotionBanner";

import { Viewport } from "../../../constants/app";
import useSavedItems from "../../../contexts/SaveItemsToStorageContext";
import useImageDataHandler from "../../../hooks/useImageDataHandler";
import { IPromotionBannerProps } from "../../../types/wif-promotion-banner";

const HtmlParser = dynamic(() => import("../../../components/HtmlParser"));

export function PromotionBanner(props: IPromotionBannerProps): JSX.Element {
  const {
    field,
    leadText,
    heading,
    bodyText,
    image,
    imageAlt,
    btnText,
    direction,
    showSaveButton,
    bgGray,
    isExternalLink,
    linkHref,
    saveItemProps,
  } = props;

  const { toggleItemInLocalStorage, isSaved } = useSavedItems();
  const imageDataHandler = useImageDataHandler();
  const imageData = imageDataHandler(image, { maxViewport: Viewport.sm });

  const handleSaveItem = () => {
    saveItemProps && toggleItemInLocalStorage(saveItemProps);
  };

  const credits =
    typeof image === "string" ? "" : image?.metadata?.rights ?? "";

  return (
    <PromotionBannerUI
      imageData={{ ...imageData, imageSrc: imageData.src }}
      leadText={leadText}
      heading={heading}
      body={<HtmlParser html={bodyText} />}
      promotionType={field}
      direction={direction}
      showSaveButton={showSaveButton}
      btnText={btnText}
      imageAlt={imageAlt}
      link={linkHref}
      saved={saveItemProps ? isSaved(saveItemProps.id) : false}
      onClick={handleSaveItem}
      isExternalLink={isExternalLink}
      backgroundColor={bgGray ? "gray" : "white"}
      credits={credits}
    />
  );
}
