import { useRouter } from "next/router";
import { useCallback } from "react";

import useViewport from "../contexts/ViewportContext";
import { formatAssetLink } from "../util/magnolia";
import { optimiseViewport, OptimiseViewportOptions } from "../util/viewport";

export interface ImageData {
  src: string;
  width: number;
  height: number;
  imageLoader?: () => string;
  imageSrc: string;
}

export type ImageDataHandlerFn = (
  image?: Wif.Mgnl.Image | string,
  options?: OptimiseViewportOptions
) => ImageData;

/**
 * Hook to generate an image handler function.
 *
 * Options can be passed to handler function to optimise image size.
 * If the image does not to bigger than "sm" in any viewport, then
 * `maxViewport` option should be set to `Viewport.sm`.
 *
 * Image sizes (width) for each viewport size:
 * - xs: 600px
 * - sm: 800px
 * - md: 900px
 * - lg: 1024px
 * - xl: 1440px
 */
export default function useImageDataHandler(): ImageDataHandlerFn {
  const { isPreview } = useRouter();
  const viewport = useViewport();

  return useCallback(
    (image, options) => imageDataHandler(image, viewport, isPreview, options),
    [isPreview, viewport]
  );
}

/**
 * Get image information from MGNL (MGNL = Magnolia)
 * @param image - Image object from MGNL
 * @param viewport - Viewport from store
 * @returns Object containing "imageSrc" (image url), "imageLoader" (loader to handle next/image)
 *  and boolean "fallback", to show a fallback image if current image is not published in MGNL
 */
export function imageDataHandler(
  image: Wif.Mgnl.Image | string | undefined,
  viewport: Wif.Viewport,
  isPreview = false,
  options?: OptimiseViewportOptions
): ImageData {
  if (typeof image === "string" || !image)
    return {
      src: "",
      width: 0,
      height: 0,
      imageLoader: undefined,
      imageSrc: "",
    };

  const { width, height } = image.metadata;
  const src = formatAssetLink(image["@link"], isPreview).toString();
  const optimisedViewport = optimiseViewport(viewport, options);

  const imageLoader = () =>
    optimisedViewport && image.renditions[optimisedViewport].link
      ? formatAssetLink(image.renditions[optimisedViewport].link, isPreview)
      : src;

  return { src, width, height, imageLoader, imageSrc: src };
}
